<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <h2 class="text-center my-4">Novi klijent</h2>
            </b-col>
            <b-col class="flex-center">
                <b-form v-on:submit.prevent="createClient" class="w-75">
                    <b-form-group :label-cols-md="4"
                                  label="Ime:"
                                  label-for="name">
                        <b-form-input id="name"
                                      type="text"
                                      size="sm"
                                      name="name"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Adresa firme:"
                                  label-for="address">
                        <b-form-input id="address"
                                      type="text"
                                      size="sm"
                                      name="address"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Adresa vlasnika:"
                                  label-for="owner_address">
                        <b-form-input id="owner_address"
                                      type="text"
                                      size="sm"
                                      name="owner_address"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Općina:"
                                  label-for="township_id">
                        <select id="township_id"
                                class="form-control form-control-sm"
                                name="township_id"
                                required>
                            <option selected disabled>Izaberite općinu</option>
                            <option v-for="township in townships" :value="township.id">
                                {{ township.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Telefon:"
                                  label-for="phone">
                        <b-form-input id="phone"
                                      type="text"
                                      size="sm"
                                      name="phone"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="JMBG:"
                                  label-for="owner_jmbg">
                        <b-form-input id="owner_jmbg"
                                      type="text"
                                      size="sm"
                                      name="owner_jmbg"
                                      maxlength="13"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Vlasnik:"
                                  label-for="owner">
                        <b-form-input id="owner"
                                      type="text"
                                      size="sm"
                                      name="owner"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Općina vlasnika:"
                                  label-for="township_owner_id">
                        <select id="township_owner_id"
                                class="form-control form-control-sm"
                                name="township_owner_id"
                                required>
                            <option selected disabled>Izaberite općinu vlasnika</option>
                            <option v-for="township in townships" :value="township.id">
                                {{ township.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Djelatnost:"
                                  label-for="activity_id">
                        <select id="activity_id"
                                class="form-control form-control-sm"
                                name="activity_id"
                                required>
                            <option selected disabled>Izaberite djelatnost</option>
                            <option v-for="activity in activities" :value="activity.id">
                                {{ activity.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="Dodatna djelatnost:"
                                  label-for="activity_secondary_id">
                        <select id="activity_secondary_id"
                                class="form-control form-control-sm"
                                name="activity_secondary_id">
                            <option selected :value="null"></option>
                            <option v-for="activity in activities" :value="activity.id">
                                {{ activity.name }}
                            </option>
                        </select>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="ID Broj:"
                                  label-for="id_number">
                        <b-form-input id="id_number"
                                      type="text"
                                      size="sm"
                                      name="id_number"
                                      pattern="[0-9]{13}"
                                      required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group :label-cols-md="4"
                                  label="PDV Broj:"
                                  label-for="pdv_number">
                        <b-form-input id="pdv_number"
                                      type="text"
                                      size="sm"
                                      name="pdv_number"
                                      pattern="[0-9]{12}"
                                      placeholder="Ostavite prazno ako nije u sustavu PDV-a">
                        </b-form-input>
                    </b-form-group>
                    <b-btn type="submit" variant="primary" class="pull-right">Potvrdi</b-btn>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        data() {
            return {
                townships: [],
                activities: []
            }
        },
        methods: {
            createClient: function (e) {
                const formData = new FormData(e.target);
                axiOsta.post(api.clients, formData).then(response => {
                        if (response.status === 201) {
                            this.$router.push('/clients/' + response.data.id);
                        }
                    }
                )
            }
        },
        created() {
            axiOsta.get(api.townships).then(response => {
                if (response.status === 200) {
                    this.townships = response.data
                }
            });
            axiOsta.get(api.activities).then(response => {
                if (response.status === 200) {
                    this.activities = response.data
                }
            })
        }
    }
</script>
